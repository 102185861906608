/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"

import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import downloadFile from './berna.pdf' 
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText, faPoll, faDatabase, faCode, faCodeBranch, faAd, faLaptopCode, faRoad, faDraftingCompass, faShoppingCart, faFileCode, faDesktop, faWindowRestore, faLayerGroup, faTabletAlt, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:100%;
`

const InputRadio = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:40px;
`

const SubmitButton = styled.button`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`  

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
` 

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff,#efefefdb);
  padding:10px;
  border-radius:3px;
  margin-top:40px;
`

const List = styled.li`
    list-style: none;
`




const consultant = () => {

  useEffect(() => {
        window.scrollTo(0,0)
    })

  const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
	consult1: file(relativePath: {eq: "consultancy/consultant11.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  consult2: file(relativePath: {eq: "consultancy/consultant12.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "consultancy/android.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "consultancy/apple.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "consultancy/thcsd.png"}) {
    childImageSharp {
      fluid(maxWidth:200){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "consultancy/casinotrac.png"}) {
    childImageSharp {
      fluid(maxWidth:300){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image5: file(relativePath: {eq: "consultancy/cssjohn.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image6: file(relativePath: {eq: "consultancy/tekvision.png"}) {
    childImageSharp {
      fluid(maxWidth:200){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image7: file(relativePath: {eq: "consultancy/css.png"}) {
    childImageSharp {
      fluid(maxWidth:60){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image8: file(relativePath: {eq: "consultancy/htmlogo.png"}) {
    childImageSharp {
      fluid(maxWidth:60){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image9: file(relativePath: {eq: "consultancy/jslogo.png"}) {
    childImageSharp {
      fluid(maxWidth:60){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image10: file(relativePath: {eq: "consultancy/linux.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image11: file(relativePath: {eq: "consultancy/woo.png"}) {
    childImageSharp {
      fluid(maxWidth:200){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image12: file(relativePath: {eq: "consultancy/wordpress.png"}) {
    childImageSharp {
      fluid(maxWidth:200){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/33fc9681-937d-40a3-9776-38ff436db3db",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    
    

        return (
         
   <div id="outer-container">
   <SEO/>
        <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
        <div id="page-wrap">

     
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
        <h1 sx={{
            color: "heading",
            m: 1,
            mt:80,
            mb:40
        
          }}>SOFTWARE DEVELOPMENT & CONSULTANCY	 </h1>

       

         <Img sx={{m:'0 auto'}}
            fluid={data.consult1.childImageSharp.fluid}
            alt="coding an app"
            style={{ width:`100%`}} 
          />
       
        <div>
        <h2>Solution</h2>
         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
       We provide consultancy & development services to both public and private companies.</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
More often than not, our clients already have an existing product & customer base with opportunities for increased monetization, but they need technical assistance to bring that further monetization to fruition.
 
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35
      }}>Touch This Media may solve the problem with: </p>
<ul>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faPoll}/> Market knowledge</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faDatabase}/> Data analytics & trend analysis</List>
<List sx={{
     listStyleType:`none`, fontSize: [1, 2],
     letterSpacing: `-0.003em`,
     lineHeight: `body`,
     "--baseline-multiplier": 0.179,
     "--x-height-multiplier": 0.35,
     color:`text`
      }}> <FontAwesomeIcon icon={faCode}/> Custom Software development</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faCodeBranch}/> Database & API Integration </List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faAd}/> Google Display & Search Ads </List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faLaptopCode}/>  Web & WordPress development </List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faRoad}/> Product road-mapping </List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faDraftingCompass}/> Productization</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faShoppingCart}/> E-commerce with WooCommerce</List>
<List sx={{
     listStyleType:`none`, fontSize: [1, 2],
     letterSpacing: `-0.003em`,
     lineHeight: `body`,
     "--baseline-multiplier": 0.179,
     "--x-height-multiplier": 0.35,
     color:`text`
      }}> <FontAwesomeIcon icon={faFileCode}/> LAMP stack  or alternative stack  </List>
<List sx={{
     listStyleType:`none`, fontSize: [1, 2],
     letterSpacing: `-0.003em`,
     lineHeight: `body`,
     "--baseline-multiplier": 0.179,
     "--x-height-multiplier": 0.35,
     color:`text`
      }}> <FontAwesomeIcon icon={faDesktop}/> Interactive self-service kiosks</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faWindowRestore}/> Web & app Hosting</List>
<List sx={{
     listStyleType:`none`, fontSize: [1, 2],
     letterSpacing: `-0.003em`,
     lineHeight: `body`,
     "--baseline-multiplier": 0.179,
     "--x-height-multiplier": 0.35,
     color:`text`
      }}> <FontAwesomeIcon icon={faLayerGroup}/> App Store/Google Play Release</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faTabletAlt}/> Digital Signage</List>
<List sx={{
      listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`
      }}> <FontAwesomeIcon icon={faNewspaper}/> Content creation</List>
</ul>



      </div>

      <div>
        <h2>Useful to</h2>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
      Retailers, utilities, casinos, casino service providers, computer hardware manufacturers, start-ups, non-profits, local/state/federal government agencies
        </p>

        <Img
            fluid={data.consult2.childImageSharp.fluid}
            alt="coding an app"
            style={{ width:`100%`}} 
          />

      </div>



       <div id="ttm-form" sx={{
        m:"0 auto",
        width:'100%',
        padding:40,
        background:'#191e2b',
        mt:50
       }}>
                <h2>Get Connected</h2>
                
                <div>
                    <form name="consultant"  onSubmit={handleOnSubmit}>
                     
                        <Input
                            type="text"
                            placeholder="name"
                            name="name"
                            required
                        />
                        <br/>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required

                        />
                        <br />
                        <Input 
                          type="tel"
                          placeholder="phone" 
                          name="phone" 
                        />
                        <br />
                         <Input 
                          type="text"
                          placeholder="company" 
                          name="company" 
                        />
                        <br />
                        <br />
                        <b>Follow up with you?</b>
                        <ul style={{listStyleType:`none`}}>
                          <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="Yes"
                                    name="FOLLOWUP"
                                />
                                <label>Yes</label>
                                </li>

                               <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="No"
                                    name="FOLLOWUP"
                                /> 
                                <label>No</label>
                                </li>
                        </ul>                                                    
                        <SubmitButton type="submit" disabled={serverState.submitting}> Send </SubmitButton>
                        {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                        </p>
                        )}
                    </form>
                </div>
            </div>

            <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="Android Logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="Apple Store Logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="THCSD Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="CasinoTrac logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt="Casino Supplies and Services Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt="Tek Visions Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image7.childImageSharp.fluid}
            alt="CSS Developer Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image8.childImageSharp.fluid}
            alt="HTML Developer Logo" 
            style={{ width:`100%`}}
          />
           <Img
            fluid={data.image9.childImageSharp.fluid}
            alt="Javascript Developer logo" 
            style={{ width:`100%`}}
          />
           <Img
            fluid={data.image10.childImageSharp.fluid}
            alt="Linux logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image11.childImageSharp.fluid}
            alt="WooCommerce logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image12.childImageSharp.fluid}
            alt="Wordpress logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>
    </div>    
    </div>
    </div>
        )
    
}



export default consultant


